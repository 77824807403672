import React, { FC } from "react";
import Dashboard from "@taxscribe/dashboard";
import OrganizationInfo from "components/shared/OrganizationInfo";
import {
  useFormGroupFilingYear,
  useJurisdictionContext,
} from "@taxscribe/ui";

import config from "config/hostnames";
import NewFormLink from "./NewFormLink";
import PageSpinner from "components/shared/PageSpinner";

export const AltDash: FC = () => {
  const { jurisdictionId } = useJurisdictionContext();
  // eslint-disable-next-line
  const [err, { loading, called, filingYear }] = useFormGroupFilingYear({
    formGroup: config.formGroup,
  });

  if (loading || !called) return <PageSpinner />;

  const currentFilingYear = filingYear || new Date().getFullYear();

  return (
    <>
      <OrganizationInfo />
      <Dashboard
        formGroup={config.formGroup}
        jurisdictionId={jurisdictionId}
        currentFilingYear={currentFilingYear}
        additionalHeaderContent={<NewFormLink />}
      />
    </>
  );
};

export default AltDash;
