import { useJurisdictionContext } from "@taxscribe/ui";
import JurisdictionNotFound from "pages/JurisdictionNotFound";
import NoEfile from "pages/NoEfile";
import React, { FC, PropsWithChildren, useEffect } from "react";

const JurisdictionGuard: FC<PropsWithChildren> = ({ children }) => {
  const {
    loading,
    jurisdictionId,
    hasEfile,
    jurisdictionName,
    jurisdictionType,
  } = useJurisdictionContext();

  useEffect(() => {
    if (jurisdictionName) {
      document.title = `TSB: ${jurisdictionName} ${jurisdictionType}`;
    }
  }, [jurisdictionName, jurisdictionType]);

  if (loading) return null;

  if (!jurisdictionId) {
    return <JurisdictionNotFound />;
  }

  if (!hasEfile) {
    return <NoEfile />;
  }

  return <>{children}</>;
};

export default JurisdictionGuard;
