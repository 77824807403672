import React, { FC } from "react";
import { gql } from "@apollo/client";
import { JurisdictionProvider } from "@taxscribe/ui";
import { Routes, Route, Navigate } from "react-router-dom";
import { SSOAuthBoundary } from "@reasongcp/react-fire-sub";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";

import HomePage from "pages/HomePage";
import Dashboard from "pages/Dashboard";
import LoginPage from "pages/LoginPage";
import LoadingPlaceholder from "./Loading";
import LoginRedirect from "./LoginRedirect";
import TaxRollSearch from "pages/TaxRollSearch";
import ResetPasswordPage from "pages/ResetPassword";
import JurisdictionGuard from "../JurisdictionGuard";
import RegistrationPage from "pages/RegistrationPage";
import StateLandingPage from "pages/StateLandingPage";
import VerificationRedirect from "./VerificationRedirect";
import VerifyPage from "pages/VerifyPage";
import config from "config/hostnames";
import Footer from "components/Footer";
import Navigation from "components/Navigation";
import Pin from "pages/Pin";
import FormSwitch from "pages/Form";

const StateRoutes: FC = () => {
  const firesubClient = useApolloClientWithAuth(config.fireSubApi);
  return (
    <Routes>
      <Route
        path="/:jurisdictionType/:jurisdictionSlug/*"
        element={
          <JurisdictionProvider>
            <JurisdictionGuard>
              <Routes>
                <Route
                  path="/register"
                  element={<RegistrationPage />}
                />
                <Route
                  path="/reset"
                  element={<ResetPasswordPage />}
                />
                <Route
                  path="/home"
                  element={<HomePage />}
                />
                <Route
                  path="/login"
                  element={<LoginPage />}
                />
                <Route
                  path="/verify"
                  element={<VerifyPage />}
                />
                <Route
                  path="/*"
                  element={
                    <SSOAuthBoundary
                      gql={gql}
                      client={firesubClient}
                      InvalidPlaceHolder={LoginRedirect}
                      LoadingPlaceHolder={LoadingPlaceholder}
                    >
                      <VerificationRedirect>
                        <Navigation />
                        <Routes>
                          <Route
                            path="/dashboard"
                            element={<Dashboard />}
                          />
                          <Route path="/search">
                            <Route
                              path=""
                              element={<TaxRollSearch />}
                            />
                            <Route
                              path=":term"
                              element={<TaxRollSearch />}
                            />
                          </Route>
                          <Route
                            path="/pin"
                            element={<Pin />}
                          />
                          <Route
                            path="/:formType/new/*"
                            element={<FormSwitch />}
                          />
                          <Route
                            path="/:formType/new-for/*"
                            element={<FormSwitch />}
                          />
                          <Route
                            path="/:formType/:formUuid/*"
                            element={<FormSwitch />}
                          />
                          <Route
                            path="/*"
                            element={<Navigate to="dashboard" />}
                          />
                        </Routes>
                        <Footer />
                      </VerificationRedirect>
                    </SSOAuthBoundary>
                  }
                />
              </Routes>
            </JurisdictionGuard>
          </JurisdictionProvider>
        }
      />
      <Route
        path="/*"
        element={<StateLandingPage />}
      />
    </Routes>
  );
};

export default StateRoutes;
