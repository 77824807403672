"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    properties: {
        individualTaxpayers: {
            type: ["string", "null"],
        },
        corporationsEtc: {
            type: ["string", "null"],
        },
        fullTimeEmployeeTitle: {
            type: "string",
        },
        principalOfficeTaxpayerTitle: {
            type: "string",
        },
        business_type: {
            type: "string",
        },
        name: {
            type: "string",
        },
        signature: {
            type: "string",
            format: "email",
            signatureMatches: true,
        },
        phoneNumber: {
            type: "string",
        },
        faxNumber: {
            type: "string",
        },
        email: {
            type: "string",
            format: "email",
        },
        authorizedAgent: {
            type: "string",
        },
    },
    required: ["signature", "email"],
};
