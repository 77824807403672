import React, { FC, useMemo } from "react";
import { gql } from "@apollo/client";
import { Metadata, NCListing } from "@taxscribe/form";
import { useAuth } from "@reasongcp/react-fire-sub";
import { NavigateButton, NCListingProviderProps } from "@taxscribe/form";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Row, Col } from "react-bootstrap";
import GenericFormWrapper from "components/shared/GenericFormWrapper";
import FormHeaderActions from "components/FormHeaderActions";
import { useFilingYear, useFormDeadline, useJurisdictionContext, useToasts } from "@taxscribe/ui";
import { useTaxbaseFormActions } from "@taxscribe/ui";
import useSubmitForm from "hooks/useSubmitForm";
import TSBPenaltyBanner from "components/shared/TSBPenaltyBanner";
import PageSpinner from "components/shared/PageSpinner";

interface ViewListingProps extends Partial<NCListingProviderProps> {
  source?: string;
  formUuid?: string;
  rawNcpts?: any;
  metadata: Metadata;
  filingYear?: string | number;
}

const CREATE_LISTING = gql`
  mutation CreateNCListing(
    $rawNcpts: JSONObject
    $source: String!
    $formData: NCListingDataInput!
    $businessName: String
    $jurisdiction: String!
  ) {
    createNCListing(
      source: $source
      formData: $formData
      ncptsData: $rawNcpts
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const UPDATE_LISTING = gql`
  mutation UpdateNCListing($formUuid: String!, $formData: NCListingDataInput!) {
    updateNCListing(uuid: $formUuid, formData: $formData)
  }
`;

const creationMutationName = "createNCListing";

interface ConditionalButtonsProps {
  readOnly?: boolean;
}

const ConditionalButtons: FC<ConditionalButtonsProps> = ({ readOnly }) => {
  if (readOnly) return null;
  return (
    <Row className="justify-content-between mt-3">
      <Col>
        <NavigateButton
          saveOnNavigate
          direction="back"
          variant="link"
          className="text-decoration-none fw-bold"
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            aria-hidden="true"
            className="me-2"
          />
          Back
        </NavigateButton>
      </Col>
      <Col className="align-self-end">
        <div className="px-2 text-end">
          <NavigateButton
            saveOnNavigate
            variant="outline-primary"
            className="ms-2"
            direction="forward"
          >
            Continue
          </NavigateButton>
        </div>
      </Col>
    </Row>
  );
};

const ViewListing: FC<ViewListingProps> = ({
  rawNcpts,
  source,
  formUuid,
  metadata,
  filingYear,
  initialState,
}) => {
  const { toastError } = useToasts();
  const { currentYear } = useFilingYear();
  const { afterDeadline, loading: deadlineLoading } =
    useFormDeadline("listing");
  const { name, hasEfile } = useJurisdictionContext();
  const { displayName, email } = useAuth() || {};
  const submit = useSubmitForm();
  const { saveAndSubmit, handleSave, readOnly } = useTaxbaseFormActions<
    typeof creationMutationName
  >({
    submit,
    source,
    formUuid,
    rawNcpts,
    jurisdiction: name,
    creationMutationName,
    status: metadata?.status,
    updateMutation: UPDATE_LISTING,
    createMutation: CREATE_LISTING,
  });

  const metadataWithUserInfo = useMemo(() => {
    // Favor the metadata attached to the form submission if it exists.
    if (metadata?.contactName) return metadata;
    const withUserInfo = {
      ...metadata,
      email: metadata?.email || email,
      contactName: displayName || email || "",
    };

    if (formUuid || !currentYear) return withUserInfo;
    return {
      ...withUserInfo,
      filingYear: String(currentYear),
    };
  }, [currentYear, metadata, displayName, email, formUuid]);

  if (deadlineLoading) {
    return <PageSpinner />;
  }

  return (
    <NCListing.Provider
      readOnly={readOnly}
      onSave={handleSave}
      hasEfile={hasEfile}
      errorToast={toastError}
      onSubmit={saveAndSubmit}
      hideSubmit={afterDeadline}
      initialState={initialState}
      metadata={metadataWithUserInfo}
    >
      <TSBPenaltyBanner
        readOnly={readOnly}
        shortName="listing"
      />
      <GenericFormWrapper
        formType="listing"
        SideNav={NCListing.Nav}
        formUuid={formUuid}
        filingYear={filingYear}
        header="Business Personal Property Listing"
        headerActions={
          <FormHeaderActions
            metadata={metadata}
            formUuid={formUuid}
            readOnly={readOnly}
          />
        }
      >
        <NCListing.Router />
        <ConditionalButtons readOnly={readOnly} />
      </GenericFormWrapper>
    </NCListing.Provider>
  );
};

export default ViewListing;
