"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const affirmationData = {
    individualTaxpayers: null,
    corporationsEtc: null,
    fullTimeEmployeeTitle: "",
    principalOfficeTaxpayerTitle: "",
    name: "",
    signature: "",
    date: "",
    phoneNumber: "",
    faxNumber: "",
    email: "",
    authorizedAgent: "",
};
exports.default = affirmationData;
